import React, { useState } from 'react';
import { Box, Button, Card, Flex, Image, Text } from 'rebass';
import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import ScaleLoader from "react-spinners/ScaleLoader";

import BoxitModal from '../../components/Modal';
import { Container } from '../../../components/container';
import { MyBoxitListItem } from '../../../components/MyBoxitList';

import BackgroundImage from '../../icons/shape-box.svg';

import BarcodeScannerImage from '../../images/barcode_scanner@2x.jpg';

import ScannerIcn from '../../icons/scanner.svg';
import CheckIcn from '../../icons/check.svg';

import ScanningPanel from './ScanniingPanel'

import Alert from '../../../components/Alert';

import ZebraPrint from '../../components/ZebraPrint';

const MyBoxitList = props => (
  <Box p="26px" css={{ height: '390px', width:'100%', overflowY: 'scroll' }}>
    <Flex {...props} flexDirection="column" />
  </Box>
);

const GET_BOX_DETAILS = gql`
  query GetBoxDetails($boxId: ID!) {
    findBoxByID(id: $boxId) {
      _id
      _ts
      size
      status
      shippingCost
      history {
        _id
        _ts
        status
      }
      recipient {
        fullname
        address {
          street
          zipCode
          city
        }
        email
        phone
      }
      account{
        contact{
          fullname
        }
      }
      deliveryAtHome
      deliveryPoint {
        _id
        name
        lat
        lng
        contact {
          fullname
          address {
            street
            zipCode
            city
          }
          phone
          email
        }
      }
      pickupPoint {
        _id
        name
        lat
        lng
        contact {
          fullname
          address {
            street
            zipCode
            city
          }
          phone
          email
        }
      }
    }
  }
`;

const ConfirmedPanel = () => (
  <>
    <Image pt="12px" src={CheckIcn} />
    <Text
      pt="20px"
      fontSize="25px"
      lineHeight="1.04"
      letterSpacing="0.2px"
      color="dustyGreen"
    >
      Μόλις προσθέσατε ένα Δέμα!
    </Text>
  </>
);

export default function BoxScanningPanel({
  addButtonText,
  helpText,
  scannedBoxes,
  isSendBoxPage,
  handleBoxScanning
}) {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [boxConfirmed, setBoxConfirmed] = useState(false);
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);

  const closeModal = () =>{
    setModalOpen(false);
  }

  const [getBoxDetails, { loading, data }] = useLazyQuery(GET_BOX_DETAILS, {
    onCompleted: data => {
      setLoadingIndicator(false);
      if (data.findBoxByID) {
        const { err } = handleBoxScanning(data.findBoxByID);
        if(err){
          setAlertMessage(err);
        }else{
          //setBoxConfirmed(true)
          //setTimeout(closeModal, 100);
        }
      } else {
        setAlertMessage('Λανθασμένος κωδικός');
      }
    },
    onError: (err)=>{
      console.log('error',err)
      setLoadingIndicator(false);
      setAlertMessage('Κάτι πήγε λάθος');
    },
    fetchPolicy: 'network-only',
  });


  const searchBox = (boxId) =>{
    setAlertMessage(null);
    setLoadingIndicator(true);
    getBoxDetails({ variables: { boxId } });
  }

  const handleClick = () => {
    setLoadingIndicator(false)
    setAlertMessage(null);
    setBoxConfirmed(false);
    setModalOpen(true);
  };

  return (
    <Container>
      <Flex pt="22px">
        <Box
          mr="16px"
          bg="white"
          width={0.7}
          css={{
            borderRadius: 16,
            minHeight: '500px',
          }}
        >
          <Flex flexWrap="wrap">
            {scannedBoxes && scannedBoxes.length > 0 ? (
              <MyBoxitList>
                {scannedBoxes.map((box, idx) => (
                  <MyBoxitListItem
                    key={`${box._id}_${idx}`}
                    id={box._id}
                    boxData={box}
                    compact
                    disablePrint
                  />
                ))}
              </MyBoxitList>
            ) : (
              <Card
                width={1}
                backgroundImage={'url(' + BackgroundImage + ')'}
                backgroundSize="auto"
                backgroundRepeat="no-repeat"
                backgroundPosition="center"
                css={{
                  height: '400px',
                }}
              />
            )}

            <Box
              width={1}
              bg="maize"
              css={{
                borderBottomLeftRadius: 16,
                borderBottomRightRadius: 16,
                height: '110px',
              }}
            >
              <Flex py="23px">
                <Button
                  py="25px"
                  px="22px"
                  ml="22px"
                  bg="blues.peacock"
                  borderRadius="4px"
                  onClick={handleClick}
                >
                  <Text
                    fontSize="14px"
                    fontWeight="bold"
                    letterSpacing="2.34px"
                  >
                    {addButtonText}
                  </Text>
                </Button>
                <BoxitModal
                  isOpen={modalOpen}
                  onBackgroundClick={() => setModalOpen(false)}
                  onEscapeKeydown={() => setModalOpen(false)}
                >
                  { !boxConfirmed ? <ScanningPanel 
                      loadingIndicator={loadingIndicator} 
                      searchBox={searchBox} 
                      alertMessage={alertMessage}
                    /> : <ConfirmedPanel />
                  }
                </BoxitModal>
               
              </Flex>
            </Box>
          </Flex>
        </Box>

        <Box
          bg="white"
          width={0.3}
          css={{
            // opacity: 0.54,
            borderRadius: 16,
            backgroundImage: 'linear-gradient(to bottom, #01549b, #032e4f)',
          }}
        >
          <Flex
            width="100%"
            px="25px"
            pt="40px"
            flexDirection="column"
            flexWrap="wrap"
          >
            <Card width={1} bg="white" p="32px">
              <Image src={BarcodeScannerImage} />
            </Card>
            <Text
              pt="22px"
              fontSize="22px"
              fontWeight="600"
              color="maize"
              textAlign="center"
              lineHeight="1.18"
            >
              {helpText}
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Container>
  );
}
