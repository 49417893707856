import React, { useState } from 'react';
import { Box, Heading, Text } from 'rebass';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import scriptLoader from 'react-async-script-loader'

import { Layout } from '../../courier/layout';
import { BoxitModalProvider } from '../../courier/components/Modal';
import BoxScanningPanel from '../../courier/components/BoxScanningPanel';
import CodeConfirmPanel from '../../courier/components/CodeConfirmPanel';
import BoxAdminPanel from '../../courier/components/BoxAdminPanel/BoxAdminPanel';
import BoxCompletedPanel from '../../courier/components/BoxCompletedPanel/BoxCompletedPanel';
import BoxitModal from '../../courier/components/Modal';
import { FooterButton, HomeNavBtn } from '../../courier/components/Buttons'

const UPDATE_BOX_TRACKING_INFO = gql`
 mutation UpdateTracking( $stationId: String!, $boxIds: [ID!],  $status: String!, $validation: String! ){
  updateBoxTrackingInfo(
    stationId: $stationId
    boxIds: $boxIds
    status: $status
    validation: $validation
  ){
    status
    history{
      status
    }
  }
}`;

const SEND_EMAIL_BOXES_INFO = gql`
 mutation SendEmail($emailData: EmailTemplateInput!){
  sendEmailTemplate(data:$emailData)
}`;

const GET_COURIER_BY_PIN = gql`
    query GetCourierByPin( $pin: String!){
      getCourierByPin(pin: $pin, _size:1){
        data{
          name
          _id
        }
      }
    } 
`

const SendABoxPage = ({location}) => {
  const stationData = location.state && location.state.station ? location.state.station : {};
  const [currentCourier,setCurrentCourier] = useState(null)
  const [stationState, setStationState] = useState(stationData);
  const [disablePinInput, setDisablePinInput] = useState(false);
  const [scannedBoxes, setScannedBoxes] = useState([]);
  const [confirmedBoxIds, setConfirmedBoxIds] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [pin, setPin] = useState(null);
  const [step, setStep] = useState(1);

  const handleBoxAdd = boxes => {
    setScannedBoxes(boxes);
  };


  const handleBackStep = () => {
    setStep(step - 1);
    setPin(null);
    setConfirmedBoxIds([]);
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };


  const openErrorModal = (msg)=>{
    setErrorMsg(msg);
    setErrorModalOpen(true);
  }

  const closeErrorModal = (msg)=>{
    setErrorMsg(null);
    setErrorModalOpen(false);
  }

  const [findCourierByPin] = useLazyQuery(GET_COURIER_BY_PIN, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setDisablePinInput(false)
      if(data.getCourierByPin){
        if(data.getCourierByPin.data.length === 1){
          setCurrentCourier(data.getCourierByPin.data)
          handleNextStep();
          closeErrorModal()
        }else{
          openErrorModal('Wrong Pin')
        }
      }else{
        openErrorModal('Wrong Pin')
      }
      
    },
    onError: (err) => {
      openErrorModal('Wrong Pin')
      setDisablePinInput(false)
    },
  });
  
  const handleCourierPin = pin =>{
    setDisablePinInput(true)
    openErrorModal('Checking')
    findCourierByPin({
      variables: {
        pin: pin,
      },
    });
  }
  
  const onSetPin = pin => {
    if(stationState.pin === pin.toString(10)){
      setPin(pin.toString(10));
      handleNextStep();
    }else{
      console.log('error', 'pins do not match');
      openErrorModal('pins do not match')
    }
  }
  const [sendBoxesEmail] = useMutation(SEND_EMAIL_BOXES_INFO, {
    onError: error => {
      console.error(`Box creation failed! ${JSON.stringify(error)}`);
      openErrorModal('Box creation failed!')
    },
    onCompleted: async data => {
      console.log('Completed sending Email')
    }
  });

  const [updateTrackingInfo] = useMutation(UPDATE_BOX_TRACKING_INFO, {
    onError: error => {
      console.error(`Box creation failed! ${JSON.stringify(error)}`);
      openErrorModal('Box creation failed!')
    },
    onCompleted: async data => {
      sendEmails();
      handleNextStep();
    }
  });

  //TODO: Check the flow . This is going to send for each box an email with one box
  const sendEmails = ()=>{
    scannedBoxes.forEach((b)=>{
      if(confirmedBoxIds.includes(b._id)){
        sendBoxesEmail({
          variables: {
            emailData: {
              emailTemplate:"RECEIVER",
              email: b.recipient.email,
              templateData: {
                name:b.account.contact.fullname.split(' ')[0],
                boxes: {
                  id: b._id,
                  size:b.size,
                  name: b.deliveryAtHome ? "Στο Σπίτι" :b.pickupPoint.name,
                  address: b.deliveryAtHome ? "": `${b.pickupPoint.contact.address.street}, ${b.pickupPoint.contact.address.zipCode}, ${b.pickupPoint.contact.address.city}`
                }
              }
            }
          },
        });
      }
    })
  }

  const updateConfirmedBoxIds = (boxId, isConfirmed)=>{
      if(isConfirmed){
        setConfirmedBoxIds([...confirmedBoxIds, boxId]);
      }else{
        setConfirmedBoxIds(confirmedBoxIds.filter(id => id !== boxId));
      }
  }

  const boxTrackingUpdate = () => {
    updateTrackingInfo({
      variables: {
        stationId: stationState._id,
        status: "dropped",
        boxIds: confirmedBoxIds,
        validation: 'pickupPoint'
      },
    });
  }


  const handleBoxScanning = (data)=>{
    if(data.status !== "shipped" && data.status !== "inTransit"){
      return {err:`Καταχώριση δεν επιτρέπεται: ${data.status}`}
    }

    if(data.pickupPoint._id !== stationState._id){
      return {err:`Καταχώριση δεν επιτρέπεται: Wrong Station`}
    }

    const filteredExist = scannedBoxes.filter(box=> box._id === data._id)
    if(filteredExist.length !== 0){
      return {err:`Καταχώριση δεν επιτρέπεται: Added already`}
    }
    
    const boxes = scannedBoxes.concat([data]);
    handleBoxAdd(boxes);
    return true
    
  }

  const Footer = () => (
    <>
      {step === 1 && (
        <HomeNavBtn text="ΕΞΟΔΟΣ" />
      )}
      {step === 5 && (
        <Text
          fontSize="22px"
          fontWeight="600"
          lineHeight="1.5"
          color="blues.peacock"
        >
          {scannedBoxes && scannedBoxes.length > 0
            ? `Έχετε προσθέσει ${scannedBoxes.length} δέματα`
            : 'Δεν έχετε προσθέσει δέματα'}
        </Text>
      )}

      {step > 2 && step < 5 && (
        <FooterButton onClick={handleBackStep}>ΕΠΙΣΤΡΟΦΗ</FooterButton>
      )}
      <Box mx="auto" />
      { (step === 2 || step === 4) &&
        <HomeNavBtn text="ΑΚΥΡΩΣΗ" />
      }
      { ( scannedBoxes.length > 0 && step === 2 ) && (
        <FooterButton onClick={handleNextStep}>ΕΠΟΜΕΝΟ</FooterButton>
      )}

      { ( confirmedBoxIds.length > 0 &&  step === 4 ) && (
        <FooterButton onClick={boxTrackingUpdate}>ΕΠΟΜΕΝΟ</FooterButton>
      )}

      {step === 5 && (
        <Heading fontSize="40px" lineHeight="0.63" color="blues.peacock">
          weboxit.com
        </Heading>
      )}
    </>
  );

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <CodeConfirmPanel
            title="Courier Login"
            description1="ΠΑΡΑΔΟΣΗ ΔΕΜΑΤΩΝ"
            buttonText="ΕΙΣΟΔΟΣ COURIER WEBOXIT"
            onSetPin={handleCourierPin}
            disablePinInput={disablePinInput}
        />
        );
      case 2:
        return (
          <BoxScanningPanel
            addButtonText="ΠΡΟΣΘΗΚΗ ΔΕΜΑΤΟΣ"
            helpText="Για να προσθέσετε νέο Δέμα επιλέξτε 'ΠΡΟΣΘΗΚΗ ΔΕΜΑΤΟΣ' και
            χρησιμοποιήστε το scanner που διαθέτει ο Σταθμός."
            scannedBoxes={scannedBoxes}
            isSendBoxPage
            handleBoxScanning={handleBoxScanning}
          />
        );
      case 3:
        return (
          <CodeConfirmPanel
            title="Μπορείτε να παραδώσετε τα δέματα σας!"
            description1="Παρακαλούμε, απευθυνθείτε στον υπάλληλο του πρακτορείου AEGEAN OIL που διαχειρίζεται τον συγκεκριμένο σταθμό, για να παραλάβει τα δέματα σας προς αποστολή."
            buttonText="ΕΙΣΟΔΟΣ ΔΙΑΧΕΙΡΙΣΤΗ ΣΤΑΘΜΟΥ WEBOXIT"
            onSetPin={onSetPin}
          />
        );
      case 4:
        return (
          <BoxAdminPanel
            addButtonText="ΠΡΟΣΘΗΚΗ ΔΕΜΑΤΟΣ"
            helpText="Για να προσθέσετε νέο Δέμα επιλέξτε 'ΠΡΟΣΘΗΚΗ ΔΕΜΑΤΟΣ' και
        χρησιμοποιήστε το scanner που διαθέτει ο Σταθμός."
            scannedBoxes={scannedBoxes}
            updateConfirmedBoxIds={updateConfirmedBoxIds}
          />
        );
      case 5:
        return (
          <BoxCompletedPanel
            infoText="Μπορείτε να παραδόσετε τα Δέματα στον Διαχειρηστή του Σταθμού για να
        τα τοποθετήσει με ασφάλεια στον κλωβό αποστολής."
          />
        );
    }
  };

  return (
    <Layout footer={() => <Footer />}>
      <BoxitModalProvider>
        <BoxitModal
            isOpen={errorModalOpen}
            onBackgroundClick={() => closeErrorModal()}
            onEscapeKeydown={() => closeErrorModal()}
          >
            <Text>
              {errorMsg}
            </Text>
      </BoxitModal>
        {renderStep()}
      </BoxitModalProvider>
    </Layout>
  );
}

export default SendABoxPage;